<template>
	<el-tabs type="border-card" @tab-click="tabClick">
		<el-tab-pane :label="`代发货 ${initText}`">
			<div style="margin: 10px 0px;" v-for="item in tableData" :key="item.id">
				<el-card style="max-width: 480px">
					<template #header>
						<div class="card-header">
							<span>
								{{item.orderNumber}}
							</span>
							<el-tag type="danger" effect="light" v-if="item.afterSale == -3">
								订单异常
							</el-tag>

							<el-tag type="primary" effect="light" v-else>
								正常
							</el-tag>
						</div>
					</template>
					<div>
						<p class="item">
						<p class="left">
							支付金额：
						</p>
						<p class="right">
							<el-tag type="primary" effect="light">
								{{item.totalFee}}
							</el-tag>
						</p>

						</p>
						<p class="item">
						<p class="left">
							下单时间：
						</p>
						<p class="right">
							{{item.placeTime}}
						</p>

						</p>
						<el-collapse>
							<el-collapse-item title="详情" name="1">
								<div>
									<div>
										<h2>
											商品信息：
										</h2>
										<div v-for="chi in item.sysOrderShos" :key="chi.id">
											<div class="goods">
												<p class="goods">
												<p>
													<el-image style="width: 100px; height: 100px"
														:src="isHttpUrl(chi.path)" fit="fill" />
												</p>
												<p class="goodsDetail" style="flex: 1; margin-left: 10px;">
												<p>
													{{chi.num}}
												</p>
												<p>
													{{chi.goodName}}
												</p>
												</p>
												</p>

												<p class="goodsPrice">
													{{chi.totalFee}}
												</p>
											</div>
										</div>
									</div>
									<div>
										<h2>
											收货人：
										</h2>
										<p class="item">
										<p class="left">
											收件人：
										</p>
										<p class="right">
											{{item.username}}
										</p>
										</p>
										<p class="item">
										<p class="left">
											收件人手机号：
										</p>
										<p class="right">
											{{item.phone}}
										</p>
										</p>
									</div>
								</div>
							</el-collapse-item>
						</el-collapse>

					</div>
					<template #footer>
						<div>
							<div>


								<el-popconfirm confirm-button-text="发货" cancel-button-text="取消" :icon="InfoFilled"
									icon-color="#626AEF" title="确定发货吗？" @confirm="submitStatus(item)"
									@cancel="cancelEvent">
									<template #reference>
										<el-button type="primary">一键发货</el-button>
									</template>
								</el-popconfirm>




							</div>
						</div>
					</template>
				</el-card>
			</div>
		</el-tab-pane>
		<el-tab-pane :label="`已收货 ${initTextStatus}`">
			<div style="margin: 10px 0px;" v-for="item in tableData" :key="item.id">
				<el-card style="max-width: 480px">
					<template #header>
						<div class="card-header">
							<span>
								{{item.orderNumber}}
							</span>
							<el-tag type="danger" effect="light" v-if="item.afterSale == -3">
								订单异常
							</el-tag>

							<el-tag type="primary" effect="light" v-else>
								正常
							</el-tag>
						</div>
					</template>
					<div>
						<p class="item">
						<p class="left">
							支付金额：
						</p>
						<p class="right">
							<el-tag type="primary" effect="light">
								{{item.totalFee}}
							</el-tag>
						</p>

						</p>
						<p class="item">
						<p class="left">
							下单时间：
						</p>
						<p class="right">
							{{item.placeTime}}
						</p>

						</p>
						<el-collapse>
							<el-collapse-item title="详情" name="1">
								<div>
									<div>
										<h2>
											商品信息：
										</h2>
										<div v-for="chi in item.sysOrderShos" :key="chi.id">
											<div class="goods">
												<p class="goods">
												<p>
													<el-image style="width: 100px; height: 100px"
														:src="isHttpUrl(chi.path)" fit="fill" />
												</p>
												<p class="goodsDetail" style="flex: 1; margin-left: 10px;">
												<p>
													{{chi.num}}
												</p>
												<p>
													{{chi.goodName}}
												</p>
												</p>
												</p>

												<p class="goodsPrice">
													{{chi.totalFee}}
												</p>
											</div>
										</div>
									</div>
									<div>
										<h2>
											收货人：
										</h2>
										<p class="item">
										<p class="left">
											收件人：
										</p>
										<p class="right">
											{{item.username}}
										</p>
										</p>
										<p class="item">
										<p class="left">
											收件人手机号：
										</p>
										<p class="right">
											{{item.phone}}
										</p>
										</p>
									</div>
								</div>
							</el-collapse-item>
						</el-collapse>

					</div>
					<template #footer v-if="item.status == 1">
						<div>
							<div>
								<el-popconfirm confirm-button-text="发货" cancel-button-text="取消" :icon="InfoFilled"
									icon-color="#626AEF" title="确定发货吗？" @confirm="submitStatus(item)"
									@cancel="cancelEvent">
									<template #reference>
										<el-button type="primary">一键发货</el-button>
									</template>
								</el-popconfirm>
							</div>
						</div>
					</template>
				</el-card>
			</div>
		</el-tab-pane>
	</el-tabs>
</template>

<script setup>
	import {
		ref,
		onMounted
	} from "vue"
	import {
		getOrderByStatus,
		deliverGoodsByAdm
	} from "@/https/api/sysOrder"

	import {
		isHttpUrl
	} from "@/utils/util"

	import {
		ElMessage,
		InfoFilled,
		cancelEvent
	} from "element-plus";

	// 默认值
	const initParams = ref(1)
	// 展示默认值
	const initText = ref("")
	const initTextStatus = ref("")

	const activeNames = ref(['1'])

	const tableData = ref([])


	async function initList() {

		const data = await getOrderByStatus(initParams.value);
		if (data && data.data) {
			tableData.value = data.data
		}
		initText.value = "(" + tableData.value.length + ")"

	}

	const submitStatus = async (dialogVisibleData) => {
		const data = {
			id: dialogVisibleData.id,
			u_phone: dialogVisibleData.u_phone,
		}
		const res = await deliverGoodsByAdm(data);
		if (res.code == 200) {
			ElMessage.success("发货成功")
			await initList();
		} else {
			ElMessage.warning("发货失败。请联系管理元")
		}
	}

	async function tabClick(e) {
		if (e.index == 0) {
			initParams.value = 1
		} else if (e.index == 1) {
			initParams.value = 2
		} else if (e.index == 2) {
			initParams.value = 3
		}



		const data = await getOrderByStatus(initParams.value);
		if (data && data.data) {
			tableData.value = data.data
		}
		if (e.index == 0) {
			initText.value = "(" + tableData.value.length + ")"
		} else if (e.index == 1) {
			initTextStatus.value = "(" + tableData.value.length + ")"
		}
	}

	const is_type = (item) => {
		const data = {
			"1": "primary",
			"2": "info",
			"-1": "success",
			"-2": "warning",
			"-100": "info"
		}


		return data[item]
	}

	const is_text = (item) => {
		const data = {
			"1": "代发货",
			"2": "待收货",
			"-1": "已完成",
			"-2": "售后中",
			"-100": "取消订单"
		}


		return data[item]
	}

	const is_text_after_sale = (item) => {


		const data = {
			"0": "正常",
			"1": "申请退款中",
			"-1": "全部退款",
			"-2": "拒绝退款",
			"-3": "订单异常",
		}


		return data[item]
	}

	const is_type_after_sale = (item) => {
		const data = {
			"0": "info",
			"1": "danger",
			"-1": "warning",
			"-2": "success",
			"-3": "warning"
		}


		return data[item]
	}




	onMounted(async () => {
		await initList();
	})
</script>

<style scoped>
	.card-header {
		font-size: 14px;
		display: flex;
		justify-content: space-between;
		align-items: center;

	}

	.item {
		font-size: 14px;
		padding: 6px 0px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px dashed #d5d5d5;

	}

	.right {
		padding: 8px 0px;
		font-size: 16px;
	}

	.footer {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.goods {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.goodsDetail {
		height: 80px;
		display: flex;
		align-content: stretch;
		justify-content: space-between;
		flex-direction: column-reverse;
		align-items: flex-start;
	}
</style>